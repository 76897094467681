
export const USER_UPDATE_AUTH = "USER_UPDATE_AUTH"
export const USER_REMOVE_AUTH = "USER_REMOVE_AUTH"

export const USER_PROFILE_RECORD = "USER_PROFILE_RECORD"
export const USER_PROFILE_RECORD_SUCCESS = "USER_PROFILE_RECORD_SUCCESS"

export const SITE_SETTING = "SITE_SETTING"
export const SITE_SETTING_SUCCESS = "SITE_SETTING_SUCCESS"

export const PRODUCTS_BY_CATEGORY = "PRODUCTS_BY_CATEGORY"
export const PRODUCTS_BY_CATEGORY_SUCCESS = "PRODUCTS_BY_CATEGORY_SUCCESS"

export const MOST_WATCHED_PRODUCT = 'MOST_WATCHED_PRODUCT'
export const MOST_WATCHED_PRODUCT_SUCCESS = 'MOST_WATCHED_PRODUCT_SUCCESS'

export const PRODUCT_DETAILS = 'PRODUCT_DETAILS'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'

export const STORE_DETAILS = 'STORE_DETAILS'
export const STORE_DETAILS_SUCCESS = 'STORE_DETAILS_SUCCESS'

export const MESSAGE_THREADS = 'MESSAGE_THREADS'
export const MESSAGE_THREADS_SUCCESS = 'MESSAGE_THREADS_SUCCESS'

export const MESSAGE_LIST = 'MESSAGE_LIST'
export const MESSAGE_LIST_SUCCESS = 'MESSAGE_LIST_SUCCESS'

export const ETH_PRICE_TOGGEL = 'ETH_PRICE_TOGGEL'

export const AD_MANAGER = 'AD_MANAGER'
export const AD_MANAGER_SUCCESS = 'AD_MANAGER_SUCCESS'

export const CAT_LANGUGAE = 'CAT_LANGUGAE'
export const CAT_LANGUGAE_SUCCESS = 'CAT_LANGUGAE_SUCCESS'

export const BANNER_IMAGES = 'BANNER_IMAGES'
export const BANNER_IMAGES_SUCCESS = 'BANNER_IMAGES_SUCCESS'

export const USER_TYPE = 'USER_TYPE'
export const CONNECT_WALLET = 'CONNECT_WALLET'
export const DISCONNECT_WALLET = 'DISCONNECT_WALLET'

export const BLOG_NEWS = 'BLOG_NEWS'
export const BLOG_NEWS_SUCCESS = 'BLOG_NEWS_SUCCESS'

export const BLOG_DETAIL = 'BLOG_DETAIL'
export const BLOG_DETAIL_SUCCESS = 'BLOG_DETAIL_SUCCESS'
export const TOP_SELLING_PRODUCTS = 'TOP_SELLING_PRODUCTS'
export const TOP_SELLING_PRODUCTS_SUCCESS = 'TOP_SELLING_PRODUCTS_SUCCESS'


// FetchMessagesListApi